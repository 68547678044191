import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PopoverBody, PopoverHeader, UncontrolledPopover } from "reactstrap";
import { RiArrowGoBackFill } from "react-icons/ri";

import styles from "@views/VehicleValidation/index.module.scss";

import Mainlayout from "@layouts/MainLayout";
import Loading from "@views/Loading/Loading";
import VehicleValidationData from "@views/VehicleValidation/components/Datos";
import VehicleValidationSales from "@views/VehicleValidation/components/Venta";
import Gallery from "@views/VehicleValidation/components/Gallery";
import EquipmentJato from "@components/EquipmentJato";
import VehicleValidationExtras from "@views/VehicleValidation/components/ExtrasCategory";
import VehicleValidationAdditionalInfo from "@views/VehicleValidation/components/AdditionalInfo";
import TabPanel from "@components/TabPanel";
import ModalPreview from "@views/VehicleValidation/components/ModalPreview";
import ModalCommentpdf from "@components/ModalCommentpdf";
import { UserContext } from "@contexts/UserContext";
import { checkVehicleValidationRol } from "@constants/roles";
import useSearchParam from "@hooks/useSearchParam";
import { createVehicle, getDraft } from "@services/Vehicle/vehicle.service";
import { errorToast, successToast } from "@helpers/toastFunctions";
import { getDealer } from "@services/Dealers/dealers.service";
import { VEHICLE_MODE } from "@constants/vehicleValidation";
import WarningDialog from "@components/WarningDialog";

const VehicleValidation = () => {
	const history = useHistory();
	const { id } = useParams();
	const { rol } = useContext(UserContext);
	const { t, ready } = useTranslation("vehicleValidation");

	const { getSearchParam } = useSearchParam();
	const currentTab = getSearchParam("tab");
	const flexicarVehicleId = getSearchParam("vehicleId");

	const [hasAccess, setHasAccess] = useState(false);
	const [type, setType] = useState("");
	const [vehicle, setVehicle] = useState({});
	const [version, setVersion] = useState({
		equipment: [],
		extras: [],
		id: null
	});

	const [dealershipInfo, setDealershipInfo] = useState(null);
	const [showModalNoLastItv, setShowModalNoLastItv] = useState(false);
	const [showModalUnfavorableItv, setShowModalUnfavorableItv] = useState(false);
	const [showModalPreview, setShowModalPreview] = useState(false);
	const [showModalCommentPdf, setShowModalCommentPdf] = useState(false);

	const getVehicle = useCallback(async () => {
		try {
			const response = await getDraft(id, type);
			const { equipment, extras, ...vehicle } = response;
			setVehicle(vehicle);
			setVersion({
				equipment,
				extras: addOthersExtraCategory(extras),
				id: response.version.id
			});
		} catch (err) {
			errorToast(err?.response?.data?.message || t("errors.getVehicle"));
		}
	}, [id, type, t]);

	const addOthersExtraCategory = (extras) => {
		if (extras.length > 0) {
			return extras.map((extra) => {
				if (extra.category && extra.category !== "") {
					return extra;
				} else {
					return {
						...extra,
						category: "Otros"
					};
				}
			});
		}
	};

	const getDealershipInfo = useCallback(async () => {
		if (vehicle?.dealership?.id) {
			try {
				const response = await getDealer("/dealers", vehicle.dealership.id);
				setDealershipInfo(response);
			} catch (err) {
				errorToast(err?.response?.data?.message || t("errors.getDealership"));
			}
		}
	}, [vehicle?.dealership?.id, t]);

	const updateExtras = (selectedExtrasIds) => {
		const selectedIdsSet = new Set(selectedExtrasIds);
		const newExtras = version.extras.map(({ items, ...rest }) => ({
			...rest,
			items: items.map((item) => ({
				...item,
				selected: selectedIdsSet.has(item.id)
			}))
		}));

		setVersion((prev) => ({ ...prev, extras: newExtras }));

		return newExtras;
	};

	const handleViewPdf = () => {
		const publishErrors = [
			{
				condition: !vehicle.priceCash || vehicle.priceCash === 0,
				message: t("errors.mandatory.vehiclePriceCash")
			},
			{ condition: !vehicle?.color, message: t("errors.mandatory.color") },
			{ condition: !vehicle.upholsteryColor, message: t("errors.mandatory.upholsteryColor") }
		];

		const errorsToShow = publishErrors.filter((error) => error.condition);
		errorsToShow.map((error) => errorToast(error.message));

		if (errorsToShow.length === 0) {
			if (!vehicle?.itvLastDate || !vehicle?.itvResult) setShowModalNoLastItv(true);
			else if (vehicle?.itvResult !== 1) setShowModalUnfavorableItv(true);
			else setShowModalPreview(true);
		}
	};

	const publishVehicle = async () => {
		try {
			await createVehicle(vehicle.id);
			successToast(t("vehiclePublished"));
			history.push(`/main/validacion-vehiculo`);
		} catch (err) {
			errorToast(t("errors.publish"));
		}
	};

	const goBack = () => {
		const originPath = history.location.pathname?.split("/");
		if (originPath.includes("validacion-vehiculo")) {
			history.push("/main/validacion-vehiculo");
		} else {
			if (history.location.state?.origin === "stock") {
				history.push("/main/stock");
			} else {
				const vehicleId = history.location.search?.split("vehicleId=")[1];
				history.push(`/main/vehicle/${vehicleId}?tab=datos`);
			}
		}
	};

	useEffect(() => {
		document.title = "Flexicar | Validación de vehiculo";

		const path = window.location.pathname.split("/");
		path.includes("validacion-vehiculo") && setType("draft");
		path.includes("edicion-vehiculo") && setType("stock");
	}, []);

	useEffect(() => {
		if (ready) {
			const hasAccess = checkVehicleValidationRol(rol);
			setHasAccess(hasAccess);
			if (!hasAccess) {
				history.push("/main/validacion-vehiculo");
				errorToast(t("errors.pagePermission"));
			}
		}
	}, [ready, t, rol, history]);

	useEffect(() => {
		if (hasAccess) getVehicle();
	}, [hasAccess, getVehicle]);

	useEffect(() => {
		if (ready) getDealershipInfo();
	}, [ready, getDealershipInfo]);

	const ActionButtons = ({ isDirty }) => (
		<div className={styles.vehicle_validation__actions}>
			{!(currentTab === "photos" || currentTab === "equipment") && (
				<button
					className="btn btn-flexicar-orange"
					type="submit"
				>
					{t("save")}
				</button>
			)}

			{type === "draft" && (
				<button
					id="publishButton"
					type="button"
					className="btn btn-flexicar-orange"
					onClick={handleViewPdf}
					disabled={isDirty}
				>
					{t("publishVehicle")}
				</button>
			)}

			{type === "stock" && (
				<button
					id="downloadPdfButton"
					type="button"
					className="btn btn-flexicar-orange"
					onClick={handleViewPdf}
					disabled={isDirty}
				>
					{t("downloadPdf")}
				</button>
			)}

			{isDirty && (
				<UncontrolledPopover
					placement="bottom"
					target={type === "draft" ? "publishButton" : "downloadPdfButton"}
					trigger="hover"
				>
					<PopoverHeader>{t("publishPopover.title")}</PopoverHeader>
					<PopoverBody>{t("publishPopover.body")}</PopoverBody>
				</UncontrolledPopover>
			)}
		</div>
	);

	const buttons = (
		<button
			className="btn btn-flexicar-orange"
			onClick={goBack}
		>
			<RiArrowGoBackFill
				size={24}
				color="#FFFFFF"
				data-toggle="tooltip"
				data-placement="top"
				title="Volver"
				role="button"
				className="mr-2"
			/>
			{t("back")}
		</button>
	);

	if (Object.keys(vehicle).length === 0) return <Loading />;

	return (
		<Mainlayout>
			<Mainlayout.Content
				contentTitle={type === "draft" ? `${t("validateVehicle")}` : `${t("editVehicle")}`}
				buttons={buttons}
			>
				<main className={styles.vehicle_validation__main}>
					<div className={styles.vehicle_validation__identification}>
						<div>
							<label htmlFor="plate">{t("licensePlate")}</label>
							<input
								id="plate"
								type="text"
								placeholder={t("licensePlate")}
								value={vehicle.plate}
								disabled
							/>
						</div>
						<div>
							<label htmlFor="chassis">{t("chassisNumber")}</label>
							<input
								id="chassis"
								type="text"
								placeholder={t("chassisNumber")}
								value={vehicle.chassisNumber}
								disabled
							/>
						</div>
					</div>

					<div className={styles.vehicle_validation__tabs}>
						<TabPanel
							id="vehicleValidation"
							searchParam="tab"
							tabs={[
								{
									headers: {
										tabName: t("tabs.data.name"),
										searchParamValue: "datos"
									},
									content: (
										<VehicleValidationData
											type={type}
											vehicle={vehicle}
											setVehicle={setVehicle}
											getVehicle={getVehicle}
											version={version}
											setVersion={setVersion}
											ActionButtons={ActionButtons}
										/>
									)
								},
								{
									headers: {
										tabName: t("tabs.sale.name"),
										searchParamValue: "sale"
									},
									content: (
										<VehicleValidationSales
											type={type}
											vehicle={vehicle}
											flexicarVehicleId={flexicarVehicleId}
											getVehicle={getVehicle}
											ActionButtons={ActionButtons}
										/>
									)
								},
								{
									headers: {
										tabName: t("tabs.photos.name"),
										searchParamValue: "photos"
									},
									content: (
										<Gallery
											type={type}
											vehicleImages={vehicle.images}
											setVehicleImages={(images) => setVehicle((prev) => ({ ...prev, images }))}
											image360Link={vehicle.images360}
											setImage360Link={(link) =>
												setVehicle((prev) => ({ ...prev, images360: link }))
											}
											vehicleId={vehicle.id}
											ActionButtons={ActionButtons}
										/>
									)
								},
								{
									headers: {
										tabName: t("tabs.equipment.name"),
										searchParamValue: "equipment"
									},
									content:
										vehicle.mode === VEHICLE_MODE.JATO ? (
											<EquipmentJato
												equipment={version.equipment}
												ActionButtons={ActionButtons}
											/>
										) : (
											<h5 className="mt-5 bold">{t("tabs.equipment.equipmentClassic")}</h5>
										)
								},
								{
									headers: {
										tabName: t("tabs.extras.name"),
										searchParamValue: "extras"
									},
									content:
										vehicle.mode === VEHICLE_MODE.JATO ? (
											<VehicleValidationExtras
												type={type}
												vehicle={vehicle}
												extras={version.extras}
												updateExtras={updateExtras}
												ActionButtons={ActionButtons}
											/>
										) : (
											<h5 className="mt-5 bold">{t("tabs.extras.extrasClassic")}</h5>
										)
								},
								{
									headers: {
										tabName: t("tabs.additionalInfo.name"),
										searchParamValue: "additionalInfo"
									},
									content: (
										<VehicleValidationAdditionalInfo
											type={type}
											vehicle={vehicle}
											setVehicle={setVehicle}
											ActionButtons={ActionButtons}
										/>
									)
								}
							]}
						/>
					</div>
				</main>
			</Mainlayout.Content>

			{showModalPreview && (
				<ModalPreview
					showModalPreview={showModalPreview}
					setShowModalPreview={setShowModalPreview}
					setShowModalCommentPdf={setShowModalCommentPdf}
					vehicle={vehicle}
				/>
			)}

			{showModalCommentPdf && (
				<ModalCommentpdf
					vehiculo={vehicle}
					publishVehicle={publishVehicle}
					impresion={showModalCommentPdf}
					setImpresion={setShowModalCommentPdf}
					type={type}
					dataTemplate={{
						vehicle,
						dealership: dealershipInfo,
						imagesPreviewUrls: vehicle?.images,
						type: "VehicleValidation"
					}}
				/>
			)}

			{showModalNoLastItv && (
				<WarningDialog
					open={showModalNoLastItv}
					titleText={t("warningModals.itvWarningModalTitle")}
					contentText={t("warningModals.noLastItvWarningModalText")}
					cancelButtonText={t("warningModals.cancel")}
					confirmButtonText={t("warningModals.accept")}
					onCancel={() => setShowModalNoLastItv((prev) => !prev)}
					onConfirm={() => {
						setShowModalNoLastItv(false);
						setShowModalPreview(true);
					}}
				/>
			)}

			{showModalUnfavorableItv && (
				<WarningDialog
					open={showModalUnfavorableItv}
					titleText={t("warningModals.itvWarningModalTitle")}
					contentText={t("warningModals.unfavorableItvWarningModalText")}
					cancelButtonText={t("warningModals.cancel")}
					confirmButtonText={t("warningModals.accept")}
					onCancel={() => setShowModalUnfavorableItv((prev) => !prev)}
					onConfirm={() => {
						setShowModalUnfavorableItv(false);
						setShowModalPreview(true);
					}}
				/>
			)}
		</Mainlayout>
	);
};

export default VehicleValidation;
