import { useEffect, useRef, useState } from "react";

import styles from "@styles/views/SellContract/index.module.scss";

import ClientData from "@views/SellContract/ClientData/ClientData";
import VehicleData from "@views/SellContract/VehicleData/VehicleData";
import SellData from "@views/SellContract/SellData/SellData";
import SellResume from "@views/SellContract/SellResume/SellResume";
import Observations from "@views/SellContract/Observations/Observations";

import Form from "@components/Form/Form";
import GenericModal from "@components/GenericModal";
import { sellContractSchema } from "@schema/SellContract";
import { getStock } from "@services/Vehicle/vehicle.service";
import { SELL_CONTRACT_PRICE_TYPES } from "@constants/sellContratDefaults";
import isEqual from "lodash.isequal";

const INITIAL_PRICES = {
	buyAndSell: false,
	priceDealerSelling: 0,
	priceCash: 0,
	priceFinanced: 0,
	priceOffer: 0
};

const SellContract = ({
	mode,
	defaultValues,
	onSubmit,
	gestoriasOptions,
	provincesOptions,
	dealershipsOptions,
	warrantyOptions,
	flexicarVehicleId,
	priceTypeEdition,
	pvpEdition,
	companieId
}) => {
	const formRef = useRef(null);

	const [areTotalAmountsValid, setAreTotalAmountsValid] = useState(false);
	const [prices, setPrices] = useState(INITIAL_PRICES);

	const [hasPricesChnaged, setHasPricesChanged] = useState(false);
	useEffect(() => {
		(async () => {
			const stockPrices = await getStock(flexicarVehicleId);

			setPrices({
				buyAndSell: stockPrices.buyAndSell,
				priceDealerSelling: stockPrices.priceDealerSelling,
				priceCash: stockPrices.priceCash,
				priceFinanced: stockPrices.priceFinanced,
				priceOffer: stockPrices.priceOffer
			});
		})();
	}, [flexicarVehicleId]);

	useEffect(() => {
		if (priceTypeEdition && !isEqual(prices, INITIAL_PRICES)) {
			const pvp = {
				[SELL_CONTRACT_PRICE_TYPES.PVP_CONTADO]: prices.priceCash,
				[SELL_CONTRACT_PRICE_TYPES.PVP_FINANCIADO]: prices.priceOffer || prices.priceFinanced,
				[SELL_CONTRACT_PRICE_TYPES.PVD]: prices.priceDealerSelling
			}[priceTypeEdition];

			if (pvp !== pvpEdition) setHasPricesChanged(true);
		}
	}, [priceTypeEdition, prices, pvpEdition]);

	return (
		<>
			<Form
				ref={formRef}
				onSubmit={onSubmit}
				defaultValues={defaultValues}
				schema={sellContractSchema}
			>
				<ClientData provincesOptions={provincesOptions} />
				<VehicleData
					dealershipsOptions={dealershipsOptions}
					companieId={companieId}
				/>
				<SellData
					gestoriasOptions={gestoriasOptions}
					dealershipsOptions={dealershipsOptions}
					provincesOptions={provincesOptions}
					warrantyOptions={warrantyOptions}
					prices={prices}
				/>
				<SellResume
					areTotalAmountsValid={areTotalAmountsValid}
					setAreTotalAmountsValid={setAreTotalAmountsValid}
				/>
				<Observations />

				<button
					className={`btn btn-flexicar-orange ${styles.createContractButton}`}
					type="submit"
					disabled={!areTotalAmountsValid}
				>
					{
						{
							create: "Crear contrato",
							edit: "Guardar"
						}[mode]
					}
				</button>
			</Form>

			{mode === "edit" && (
				<GenericModal
					isOpen={hasPricesChnaged}
					setIsOpen={setHasPricesChanged}
					headContent="Aviso de cambios en el precio del contrato"
					bodyContent={
						<div className={styles.changePriceModal}>
							<span>
								Atención, el contrato ha sufrido un cambio de precio, antes el valor era{" "}
								{pvpEdition} y ahora el valor es {formRef.current?.getValues("sellInfo.pvp")}.
							</span>
							<span>
								Por favor, actualiza los datos necesarios y dé a “Guardar“ para actualizar el
								contrato.
							</span>
						</div>
					}
					footerContent={
						<button
							className="btn-flexicar-orange"
							onClick={() => setHasPricesChanged(false)}
						>
							Aceptar
						</button>
					}
				/>
			)}
		</>
	);
};

export default SellContract;
