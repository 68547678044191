import React, { useState, useEffect, useContext, useCallback } from "react";
import { post } from "@services/axiosCalls";
import { API } from "@services/urlConstants";
import { quotationUrl } from "@services/MMA/mma.service";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { errorToast} from "@helpers/toastFunctions";
import { UserContext } from "@contexts/UserContext";
import DatepickerFilters from "@components/DatepickerFilters";
import moment from "moment";
import { useTranslation } from "react-i18next";

import Select from "@components/Select"

const ModalMMA = ({
  setModal,
  status,
  lead,
  client
}) => {
  const { t } = useTranslation("modalMMA");
  const { user } = useContext(UserContext);
  const [sendText, setSendText] = useState("Solicitar cotización")
  const [quotation, setQuotation] = useState(null);
  const [ddbbQuotation, setDdbbQuotation] = useState(null);
  const [data, setData] = useState({
    nombre: "",
    apellidos: "",
    telefono: "",
    dni: "",
    anios_asegurado: 0,
    partes: 0,
    compania_actual: [{ label: 'MUTUA MADRILEÑA', value: "999"}],
    estado_civil: [], 
    sexo: [], 
    codigo_postal: 0,
    fecha_nacimiento_f: "",
    fecha_expedicion_f: "",
    carnet: [{ label: 'B', value: "B"}]
  })

  const carnet_list = [
    { label: 'A1', value: "A1"},
    { label: 'A2', value: "A2"},
    { label: 'B', value: "B"},
    { label: 'C', value: "C"}
  ]

  const estado_civil_list = [
    { label: 'Soltero', value: "Soltero"},
    { label: 'Casado', value: "Casado"}
  ]

  const sexo_list = [
    { label: 'Hombre', value: "Hombre"},
    { label: 'Mujer', value: "Mujer"}
  ]

  const companie_list = [
    { label: 'MUTUA MADRILEÑA', value: "999"},
    { label: 'ALLIANZ-RAS', value: "004"},
    { label: 'DKV SEGUROS', value: "014"},
    { label: 'CATALANA OCCIDENTE S.A. SEGUROS Y REASEGUROS', value: "015"},
    { label: 'BANCO VITALICIO', value: "020"},
    { label: 'SEGUROS BILBAO SA', value: "021"},
    { label: 'CASER', value: "030"},
    { label: 'MERCURIO', value: "034"},
    { label: 'LIBERTY SEGUROS', value: "046"},
    { label: 'FIATC', value: "053"},
    { label: 'LAGUNARO', value: "055"},
    { label: 'REGAL INSURANCE', value: "056"},
    { label: 'GES SEGUROS', value: "057"},
    { label: 'LA ESTRELLA', value: "060"},
    { label: 'ZURICH', value: "069"},
    { label: 'MUTUA VALENCIANA AUTOMOVILISTA', value: "079"},
    { label: 'AXA', value: "083"},
    { label: 'EUROMUTUA', value: "084"},
    { label: 'MEDIODIA', value: "085"},
    { label: 'MAPFRE', value: "090"},
    { label: 'MUTUA GENERAL DE SEGUROS', value: "095"},
    { label: 'MUTUALIDAD DE LEVANTE', value: "096"},
    { label: 'GROUPAMA', value: "105"},
    { label: 'MUTUA MADRILEÑA DE TAXIS', value: "110"},
    { label: 'LA PATRIA HISPANA', value: "111"},
    { label: 'HELVETIA COMPAÑIA SUIZA, S.A.', value: "117"},
    { label: 'SOLISS', value: "127"},
    { label: 'UNION ALCOYANA', value: "134"},
    { label: 'REALE AUTOS', value: "140"},
    { label: 'U.M.A.', value: "144"},
    { label: 'PELAYO', value: "150"},
    { label: 'COMPAÑÍA EXTRANJERA', value: "152"},
    { label: 'MUSSAP', value: "169"},
    { label: 'MUTUAL FLEQUERA DE CATALUÑA', value: "171"},
    { label: 'IMPERIO', value: "174"},
    { label: 'WINTERTHUR', value: "190"},
    { label: 'ETERNA ASEGURADORA, S.A.', value: "194"},
    { label: 'SEGURAUTO', value: "197"},
    { label: 'NATIONALE NEDERLANDEN', value: "208"},
    { label: 'MUTUA CATALANA DE SEGUROS', value: "217"},
    { label: 'MUTUA DE SEGUROS DE TARRASA', value: "219"},
    { label: 'MUTUA TINERFEÑA', value: "222"},
    { label: 'ATLANTIS', value: "224"},
    { label: 'LINEA DIRECTA ASEGURADORA', value: "233"},
    { label: 'GENESIS', value: "234"},
    { label: 'DIRECT SEGUROS', value: "235"},
    { label: 'FENIX DIRECTO', value: "300"},
    { label: 'FIDELIDADE ESPAÑA', value: "302"},
    { label: 'MUTUA DE PROPIETARIOS', value: "308"},
    { label: 'SEGURCAIXA', value: "313"},
    { label: 'CLICKSEGUROS SEGUROS Y REASEGUROS S.A.U', value: "314"},
    { label: 'SABADELL ASEGURADOR', value: "316"},
    { label: 'CONSORCIO COMPENSACION DE SEGUROS', value: "887"},
    { label: 'OTRAS', value: "999"},
    { label: 'BALUMBA', value: "800"},
    { label: 'BBVA SEGUROS', value: "801"},
    { label: 'HDI', value: "802"},
    { label: 'PSN AMA', value: "803"},
    { label: 'INEAS', value: "804"},
    { label: 'QUALITAS', value: "805"}
  ];


  const getclientData = useCallback(async () => {
		setData((prevData) => ({
			...prevData,
			nombre: client.name,
			apellidos: client?.firstSurname ,
			telefono: client?.contact.primaryPhone
		}));
	}, [client]);

  const getleadData = useCallback(async () => {
    if(lead && !quotation){
      const response = await post(API.LOAD_MMA_QUOTATION, {id_lead: lead.id_lead})
      if(response){
        setDdbbQuotation(response.id)
        setQuotation(JSON.parse(response.q_data))
      }
    }
  }, [lead, quotation]);

  const updateQuotation = async () => {
    try{
      const response = await quotationUrl(lead.id_lead, user)

      if (response.publicUrl) {
        return window.open(response.publicUrl, '_blank');
      }
    }
    catch (e) {
      errorToast(<span>{t("errors.errorMutuaAccess")}</span>);
    }
    };

  const receiveExpeditionDate = (date) => {
    setData({...data, fecha_expedicion_f: date})
  }

  const receiveBirthdayDate = (date) => {
    setData({...data, fecha_nacimiento_f: date})
  }

  const setInsuredYears = (e) => {
    const value = e.target.value;
    setData({ ...data, anios_asegurado: value === '' ? '' : Number(value) });
  };

  const handlePartesChange = (e) => {
    const value = e.target.value;
    setData({ ...data, partes: value === '' ? '' : Number(value) });
  };
  
  const cotizar = async () => {
    const anio = 31560000000;
    const hoy = new Date();
    const edadCarnet = new Date(data.fecha_expedicion_f).getTime() - new Date(data.fecha_nacimiento_f).getTime();
    const aniosCarnet = hoy.getTime() - new Date(data.fecha_expedicion_f).getTime();
    const mayorEdad = hoy.getTime() - new Date(data.fecha_nacimiento_f).getTime();
    if (mayorEdad < (anio * 18)) {
      return errorToast(<span>{t("errors.errorMutuaAge")}</span>)
    }
    if (edadCarnet < (anio * 18)) {
      return errorToast(<span>{t("errors.errorDates")}</span>)
    }
    if (data.anios_asegurado * anio > aniosCarnet) {
      return errorToast(<span>{t("errors.errorMutuaYears")}</span>)
    }
    if (data.codigo_postal.length !== 5) {
      return errorToast(<span>{t("errors.errorMutuaPostalCode")}</span>)
    }
    if (data.fecha_nacimiento_f.length === 0) {
      return errorToast(<span>{t("errors.errorMutuaBirthDate")}</span>)
    }
    if (data.fecha_expedicion_f.length === 0) {
      return errorToast(<span>{t("errors.errorMutuaLicenseDate")}</span>)
    }
    if (data.carnet.length === 0) {
      return errorToast(<span>{t("errors.errorMutuaLicenseType")}</span>)
    }
    if (data.estado_civil.length === 0) {
      return errorToast(<span>{t("errors.errorMutuaMaritalStatus")}</span>)
    }
    if (data.sexo.length === 0) {
      return errorToast(<span>{t("errors.errorMutuaSex")}</span>)
    }
    if (data.compania_actual.length === 0) {
      return errorToast(<span>{t("errors.errorMutuaCurrentCompany")}</span>)
    }
    if (data.anios_asegurado.length === 0) {
      return errorToast(<span>{t("errors.errorMutuaYearsInsured")}</span>)
    }
    if (data.partes.length === 0) {
      return errorToast(<span>{t("errors.errorMutuaPartsDeclared")}</span>)
    }
    if (data.apellidos.length === 0) {
      return errorToast(<span>{t("errors.errorMutuaLastName")}</span>)
    }
    if (data.nombre.length === 0) {
      return errorToast(<span>{t("errors.errorMutuaName")}</span>)
    }
    if (data.dni.length < 9) {
      return errorToast(<span>{t("errors.errorMutuaDni")}</span>)
    }
    data.tipoDocumento = isNaN(data.dni.charAt(0)) ? "NIE" : "NIF";
    data.nombre.substring(0, 15);

    data.matricula = lead.matricula;
    data.bastidor = lead.bastidor;
    data.fecha_matriculacion = lead.fecha_matriculacion.substring(0, 10);

    data.fecha_expedicion = moment(data.fecha_expedicion_f).format("YYYY-MM-DD");
    data.fecha_nacimiento = moment(data.fecha_nacimiento_f).format("YYYY-MM-DD");
 
    let error
  
    try {
      const response = await post(API.MMA_QUOTATION, {
        data,
        datos: quotation ? quotation : null,
        quotation: ddbbQuotation ? ddbbQuotation : null,
        registro: error ? error : null,
        id_lead: lead.id_lead,
        agente: user

      });
 
      if (response) {  
        setQuotation(response)
      } else {
        return errorToast(<span>{t("errors.errorMutuaRequest")}</span>)
        
      }

    }
    catch (e) {
      setSendText("Reintentar")
      if (e?.response?.status === 500) {
        errorToast(<span>{t("errors.errorMutuaRequest")}</span>) 
      }
      else if (e?.response?.status === 404) {
        return errorToast(<span>{t("errors.errorMutuaVehicle")}</span>)
      }
      else if (e?.response?.status === 400) {
        return errorToast(<span>{t("errors.errorMutuaData")}</span>)
      }
      else {
        return errorToast(<span>{t("errors.errorMutuaQuote")}</span>)
      }

    }  
    
  }

  useEffect(() => {
		if (client) {
			getclientData();
		}
	}, [client, getclientData]);

	useEffect(() => {
		if (lead) {
			getleadData();
		}
	}, [lead, getleadData]);

  return (
    <Modal
      className="modal-lg"
      centered
      isOpen={status}
      toggle={() => setModal()}
    >
    <ModalHeader>Cotizar Seguro para {lead?.nombre_marca_v} {lead?.nombre_modelo_v} {lead?.matricula}</ModalHeader>
    <ModalBody className="p-4">
            {quotation ? 
            <div style={{textAlign: "-webkit-center"}}>
            <p>Referencia: <span>{quotation.id}</span></p>
            <table className="default" style={{ width: "100%", tableLayout: "fixed" }}>
              <thead>
                <tr>
                  <th style={{ width: "40%"}}>Seguro</th>
                  <th style={{ width: "30%", textAlign: "center" }}>Precio</th>
                  <th style={{ width: "30%", textAlign: "center" }}>Franquicia</th>
                </tr>
              </thead>
              <tbody>
                {quotation.offers.map((item, i) => {
                  if (item.id !== "1 3") {
                    return (
                      <tr key={`quotations-${item.name}-${i}`}>
                        <td style={{ padding: "8px" }}>{item.name}</td>
                        <td style={{ textAlign: "center", padding: "8px" }}>{item.cost} €</td>
                        <td style={{ textAlign: "center", padding: "8px" }}>
                          {item.assurances[2]?.excessInsurance || "-"}
                        </td>
                      </tr>
                    );
                  }
                  return <></>;
                })}
              </tbody>
            </table>
            </div>
            :
            <>
            <div className=" mt-2 row">
            <div className="col-md-4 col-sm">
              <div><span className="tittle_sub ml-1">Nombre:</span>
              <input value={data.nombre} maxLength={15} onChange={(e)=>setData({...data, nombre: e.target.value})}/></div>
              <div><span className="tittle_sub ml-1">DNI / NIF:</span>
              <input value={data.dni} onChange={(e)=>setData({...data, dni: e.target.value})}/></div>
              <div><span className="tittle_sub ml-1">Compañía actual:</span>
              <Select
                placeholder="Seleccione compañía"
                options={companie_list}
                value={data.compania_actual}
                onChange={(value) => {
                  setData({...data, compania_actual: [value] })}
                }
              /></div>
              <div><span className="tittle_sub ml-1">Código Postal:</span>
              <input value={data.codigo_postal} onChange={(e)=>setData({...data, codigo_postal: e.target.value})}/></div>
              <div><span className="tittle_sub ml-1">Fecha expedición:</span>
              <DatepickerFilters
                date={data.fecha_expedicion_f}
                sendDate={receiveExpeditionDate}
              />
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div><span className="tittle_sub ml-1">Apellidos:</span>
              <input value={data.apellidos} onChange={(e)=>setData({...data, apellidos: e.target.value})}/></div>
              <div><span className="tittle_sub ml-1">Años asegurado:</span>
              <input value={data.anios_asegurado} type="number" onChange={setInsuredYears}/></div>
              <div><span className="tittle_sub ml-1">Estado civil:</span>
              <Select
                placeholder="Seleccione Estado Civil"
                options={estado_civil_list}
                value={data.estado_civil}
                onChange={(value) => {
                  setData({...data, estado_civil: [value] })}
                }
                /></div>
              <div><span className="tittle_sub ml-1">Fecha nacimiento:</span>
              <DatepickerFilters
                date={data.fecha_nacimiento_f}
                sendDate={receiveBirthdayDate}  
              />
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div><span className="tittle_sub ml-1">Teléfono:</span>
              <input value={data.telefono} onChange={(e)=>setData({...data, telefono: e.target.value})}/></div>
              <div><span className="tittle_sub ml-1">Partes declarados:</span>
              <input value={data.partes} type="number" onChange={handlePartesChange} /></div>
              <div><span className="tittle_sub ml-1">Sexo:</span>
              <Select
                placeholder="Seleccione Sexo"
                options={sexo_list}
                value={data.sexo}
                onChange={(value) => { 
                  setData({...data, sexo: [value] })}
                }
                /></div>
              <div><span className="tittle_sub ml-1">Tipo Carnet:</span>
              <Select
                placeholder="Seleccione carnet"
                options={carnet_list}
                value={data.carnet}
                onChange={(value) => { 
                  setData({...data, carnet: [value] })}
                }
              /></div>
              </div>
            
          </div>
            </>
        }
    </ModalBody>
      <ModalFooter>
       
      {(!quotation || !quotation.editUrl) && <button type="button" className="btn btn-light btn-outline-dark btn-flexicar" onClick={() => cotizar()}>{sendText}</button>}
        {sendText === "Reintentar" && <button type="button" className="btn btn-light btn-outline-dark btn-flexicar" onClick={() => updateQuotation()}>Alta Manual</button>}
        {quotation && quotation.editUrl && <button type="button" className="btn btn-light btn-outline-dark btn-flexicar" onClick={() => updateQuotation()}>Acceder</button>}
      <button type="button" className="btn btn-light btn-outline-dark btn-flexicar" onClick={() => setModal()}>Cerrar</button>
      </ModalFooter>
  </Modal>
  );
};

export default ModalMMA;